var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "律师费", fixed: "" },
          slot: "header",
        },
        [
          !_vm.isShare
            ? _c(
                "fb-button",
                {
                  attrs: {
                    slot: "left",
                    icon: "back",
                    size: "small",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.backToNative()
                    },
                  },
                  slot: "left",
                },
                [_vm._v(" 返回 ")]
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "tool-head",
                {
                  attrs: { type: "lawyerFee" },
                  on: { clickItem: _vm.clickItem },
                },
                [
                  _c("font-size-comp", {
                    ref: "fontSizeComp",
                    on: { setFontSize: _vm.setFontSize },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tools-lawwyerFee",
          staticStyle: { "font-size": "14px !important" },
          style: _vm.createCaseWrapperStyle,
          attrs: { id: "tools" },
        },
        [
          _c("div", { staticClass: "interest-content" }, [
            _c(
              "div",
              { staticClass: "box-top", staticStyle: { padding: "0" } },
              [
                _c("div", { staticClass: "left-box" }, [
                  _c("div", { staticClass: "province-input-wrapper" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.province,
                          expression: "province",
                        },
                      ],
                      ref: "inputRef1",
                      staticStyle: { "line-height": "20px" },
                      attrs: { type: "text", placeholder: "请填写省份" },
                      domProps: { value: _vm.province },
                      on: {
                        focus: _vm.provinceFocus,
                        blur: _vm.provinceBlur,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.province = $event.target.value
                        },
                      },
                    }),
                    _vm._v("    "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.province,
                          expression: "province",
                        },
                      ],
                      staticClass: "iconfont icon-clear-2 mjechanism-clear",
                      staticStyle: { color: "#c8c9cc" },
                      on: { click: _vm.DeleteProvince },
                    }),
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showProvincePanel,
                            expression: "showProvincePanel",
                          },
                        ],
                        staticClass: "liebiao",
                        on: {
                          click: function ($event) {
                            _vm.showProvincePanel = false
                          },
                        },
                      },
                      _vm._l(_vm.provinceOption, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleSelectProvice(item.value)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(item.text))])]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "right-box" },
                  [
                    _c(
                      "van-field",
                      {
                        attrs: {
                          "input-align": "right",
                          readonly: "",
                          clickable: "",
                          clearable: "",
                          name: "picker",
                          value: _vm.caseType,
                          placeholder: "点击选择案件类型",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showCaseTypePanel = true
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-xialaxuanze",
                          attrs: { slot: "right-icon" },
                          slot: "right-icon",
                        }),
                      ]
                    ),
                    _c(
                      "van-popup",
                      {
                        style: { height: "35%" },
                        attrs: { position: "bottom" },
                        model: {
                          value: _vm.showCaseTypePanel,
                          callback: function ($$v) {
                            _vm.showCaseTypePanel = $$v
                          },
                          expression: "showCaseTypePanel",
                        },
                      },
                      [
                        _c("van-picker", {
                          attrs: {
                            "show-toolbar": "",
                            columns: _vm.caseTypeOption,
                          },
                          on: {
                            cancel: function ($event) {
                              _vm.showCaseTypePanel = false
                            },
                            confirm: _vm.handleSelectCaseType,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm.caseType
              ? _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "left" }, [
                    _vm.showCaseStage
                      ? _c("span", { staticClass: "box-title" }, [
                          _vm._v("案件阶段"),
                        ])
                      : _c("span", { staticClass: "box-title" }, [
                          _vm._v("标的额 " + _vm._s(this.dealTargetAmount)),
                        ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _vm.showCaseStage
                        ? [
                            _c("van-field", {
                              attrs: {
                                "input-align": "right",
                                clickable: "",
                                clearable: "",
                                readonly: "",
                                name: "picker",
                                value: _vm.caseStage,
                                placeholder: "点击选择案件阶段",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showCaseStagePicker = true
                                },
                              },
                            }),
                            _c(
                              "van-popup",
                              {
                                style: { height: "50%" },
                                attrs: { position: "bottom" },
                                model: {
                                  value: _vm.showCaseStagePicker,
                                  callback: function ($$v) {
                                    _vm.showCaseStagePicker = $$v
                                  },
                                  expression: "showCaseStagePicker",
                                },
                              },
                              [
                                _c("van-picker", {
                                  attrs: {
                                    "show-toolbar": "",
                                    columns: _vm.caseStageOption,
                                  },
                                  on: {
                                    cancel: function ($event) {
                                      _vm.showCaseStagePicker = false
                                    },
                                    confirm: _vm.handleSelectCaseStage,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("van-number-keyboard", {
                                  style: { height: "35%" },
                                  attrs: {
                                    show: _vm.showkeyboard,
                                    theme: "custom",
                                    "extra-key": ".",
                                    maxlength: 16,
                                    "close-button-text": "完成",
                                  },
                                  on: {
                                    input: _vm.onInput,
                                    blur: _vm.handleBlurTargetAmount,
                                  },
                                  model: {
                                    value: _vm.targetAmount,
                                    callback: function ($$v) {
                                      _vm.targetAmount = $$v
                                    },
                                    expression: "targetAmount",
                                  },
                                }),
                                _c("van-field", {
                                  attrs: {
                                    "input-align": "right",
                                    clickable: "",
                                    readonly: "",
                                    clearable: "",
                                    name: "picker",
                                    value: _vm.targetAmount,
                                    placeholder: "点击输入标的额",
                                  },
                                  nativeOn: {
                                    touchstart: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleFocusAmount.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                }),
                                _c("span", [_vm._v(" 元")]),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.result,
                    expression: "result",
                  },
                ],
                staticClass: "box",
                staticStyle: { color: "red" },
              },
              [
                _c("div", { staticClass: "left" }, [
                  _c("span", { staticClass: "box-title" }, [_vm._v("律师费")]),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.result,
                          expression: "result",
                        },
                      ],
                      attrs: { id: "lawyerfeeResult" },
                    },
                    [
                      _vm.result.minResult != _vm.result.maxResult
                        ? _c(
                            "span",
                            { staticStyle: { "font-size": "16px !important" } },
                            [
                              _vm._v(
                                _vm._s(_vm.result.minResult) +
                                  " 至 " +
                                  _vm._s(_vm.result.maxResult) +
                                  " 元"
                              ),
                            ]
                          )
                        : _c(
                            "span",
                            { staticStyle: { "font-size": "16px !important" } },
                            [_vm._v(_vm._s(_vm.result.minResult) + " 元")]
                          ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.result,
                  expression: "result",
                },
              ],
              staticClass: "interest-result",
              staticStyle: { color: "orange" },
              attrs: { id: "yiju" },
            },
            [
              _c("span"),
              _c(
                "span",
                _vm._l(
                  _vm.result.lawyerFeeStandardTextArr,
                  function (item, index) {
                    return _c("div", { key: index }, [
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(item.startText) +
                            "      " +
                            _vm._s(item.endText) +
                            " "
                        ),
                      ]),
                    ])
                  }
                ),
                0
              ),
            ]
          ),
          _c("div", { staticClass: "btns-result" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.result,
                    expression: "result",
                  },
                ],
                on: {
                  click: function ($event) {
                    _vm.showYiju = !_vm.showYiju
                  },
                },
              },
              [_vm._v("依据")]
            ),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.result,
                  expression: "!result",
                },
              ],
            }),
            _c("span", [
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.copy },
                },
                [_vm._v("复制")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.iphone,
                      expression: "!iphone",
                    },
                  ],
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.download },
                },
                [_vm._v("下载")]
              ),
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.share },
                },
                [_vm._v("分享")]
              ),
              _c("span", { on: { click: _vm.feedback } }, [_vm._v("反馈")]),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showYiju,
                  expression: "showYiju",
                },
              ],
              staticClass: "result-yj",
            },
            [_vm._v(" " + _vm._s(_vm.yjtext) + " ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }