/* eslint-disable no-unused-vars */
<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" title="律师费" fixed>
      <fb-button v-if="!isShare" slot="left" icon="back" @click="backToNative()" size="small" type="primary"> 返回 </fb-button>
      <div slot="right">
        <tool-head type="lawyerFee" @clickItem="clickItem">
          <font-size-comp @setFontSize="setFontSize" ref="fontSizeComp" />
        </tool-head>
      </div>
    </fb-header>
    <div id="tools" class="tools-lawwyerFee" style="font-size: 14px !important" :style="createCaseWrapperStyle">
      <div class="interest-content">
        <!-- <div class="box-top" style="padding:0">
          <van-dropdown-menu active-color="#1989fa">
            <van-dropdown-item
              v-model="province"
              :options="provinceOption"
              @change="handleSelectProvice"
            />
            <van-dropdown-item
              v-model="caseType"
              :options="caseTypeOption"
              @change="handleSelectCaseType"
            />
          </van-dropdown-menu>
        </div> -->
        <div class="box-top" style="padding: 0">
          <div class="left-box">
            <div class="province-input-wrapper">
              <input type="text" ref="inputRef1" style="line-height: 20px" v-model="province" placeholder="请填写省份" @focus="provinceFocus" @blur="provinceBlur" />
              &nbsp;&nbsp;
              <i style="color: #c8c9cc" class="iconfont icon-clear-2 mjechanism-clear" v-show="province" @click="DeleteProvince"></i>
              <ul class="liebiao" v-show="showProvincePanel" @click="showProvincePanel = false">
                <li v-for="(item, index) in provinceOption" :key="index" @click.stop="handleSelectProvice(item.value)">
                  <span>{{ item.text }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="right-box">
            <van-field input-align="right" readonly clickable clearable name="picker" :value="caseType" placeholder="点击选择案件类型" @click="showCaseTypePanel = true">
              <i slot="right-icon" class="iconfont icon-xialaxuanze"></i>
            </van-field>
            <van-popup v-model="showCaseTypePanel" position="bottom" :style="{ height: '35%' }">
              <van-picker show-toolbar :columns="caseTypeOption" @cancel="showCaseTypePanel = false" @confirm="handleSelectCaseType" />
            </van-popup>
          </div>
        </div>
        <div class="box" v-if="caseType">
          <div class="left">
            <span v-if="showCaseStage" class="box-title">案件阶段</span>
            <span v-else class="box-title">标的额&nbsp;{{ this.dealTargetAmount }}</span>
          </div>
          <div class="right">
            <template v-if="showCaseStage">
              <van-field input-align="right" clickable clearable readonly name="picker" :value="caseStage" placeholder="点击选择案件阶段" @click="showCaseStagePicker = true" />
              <van-popup v-model="showCaseStagePicker" position="bottom" :style="{ height: '50%' }">
                <van-picker show-toolbar :columns="caseStageOption" @cancel="showCaseStagePicker = false" @confirm="handleSelectCaseStage" />
              </van-popup>
            </template>
            <template v-else>
              <div style="display: flex; align-items: center">
                <van-number-keyboard
                  :show="showkeyboard"
                  theme="custom"
                  v-model="targetAmount"
                  :style="{ height: '35%' }"
                  extra-key="."
                  :maxlength="16"
                  @input="onInput"
                  close-button-text="完成"
                  @blur="handleBlurTargetAmount"
                />
                <van-field input-align="right" clickable readonly clearable @touchstart.native.stop="handleFocusAmount" name="picker" :value="targetAmount" placeholder="点击输入标的额" />
                <span>&nbsp;元</span>
              </div>
            </template>
          </div>
        </div>

        <div class="box" style="color: red" v-show="result">
          <div class="left">
            <span class="box-title">律师费</span>
          </div>
          <div class="right">
            <span id="lawyerfeeResult" v-show="result">
              <span v-if="result.minResult != result.maxResult" style="font-size: 16px !important">{{ result.minResult }}&nbsp;至&nbsp;{{ result.maxResult }}&nbsp;元</span>
              <span v-else style="font-size: 16px !important">{{ result.minResult }} 元</span>
            </span>
          </div>
        </div>
      </div>
      <div class="interest-result" style="color: orange" id="yiju" v-show="result">
        <span></span>
        <span>
          <div v-for="(item, index) in result.lawyerFeeStandardTextArr" :key="index">
            <span style="margin-right: 10px"> {{ item.startText }} &nbsp; &nbsp; &nbsp;{{ item.endText }} </span>
          </div>
        </span>
      </div>
      <div class="btns-result">
        <span @click="showYiju = !showYiju" v-show="result">依据</span>
        <span v-show="!result"></span>
        <span>
          <span style="margin-right: 10px" @click="copy">复制</span>
          <span v-show="!iphone" style="margin-right: 10px" @click="download">下载</span>
          <span style="margin-right: 10px" @click="share">分享</span>
          <span @click="feedback">反馈</span>
        </span>
      </div>
      <div class="result-yj" v-show="showYiju">
        {{ yjtext }}
      </div>
    </div>
  </fb-page>
</template>

<script>
import longUrl2ShortUrl from '@/common/longUrl2ShortUrlUtils.js'
import { mapState } from 'vuex'
import fontSizeComp from '../../components/font-size-comp.vue'
import toolHead from '../../components/toolHead.vue'
import AdapterUtil from '../../components/AdapterUtil'
import goBackMixin from '@mixins/goBack'
// import addressSelector from '../../components/addressSelector.vue'
import { qiniuBase } from '~api-config'
export default {
  name: 'deathCompensation',
  mixins: [goBackMixin],
  components: {
    // addressSelector,
    fontSizeComp,
    toolHead,
  },
  data() {
    return {
      isShare: false,
      province: '', // 地址
      showProvincePanel: false,
      BR: false, // 地址输入框失焦
      provinceOption: [], // 地址
      caseType: '', // 案件类型
      showCaseTypePanel: false,
      typeBR: false, // 案件类型失焦
      caseTypeOption: [],
      caseStage: '', // 案件阶段
      caseStageOption: [],
      showCaseStage: false,
      showCaseStagePicker: false, // 选择案件阶段面板
      targetAmount: '0', // 标的额
      showkeyboard: false, // 标的额
      result: '',
      showResult: false,
      showYiju: false,
      currentIndex: 0,
      iphone: false,
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    createCaseWrapperStyle() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      const h = document.documentElement.clientHeight
      const height = h - 44 * dpr + 30
      const style = {
        height: `${height}px`,
      }
      return style
    },
    dealTargetAmount() {
      if (this.targetAmount && Number(this.targetAmount)) {
        const targetAmount = Number(this.targetAmount)
        const w = parseInt(targetAmount / 10000)
        const y = parseInt(targetAmount % 10000) > 0 ? parseInt(targetAmount % 10000) : ''
        return w > 0 ? w + '万' + y + '元' : targetAmount + '元'
      } else {
        return ''
      }
    },
    // 依据文字
    yjtext() {
      const { province, caseType, caseStage } = this
      let text = ''
      text += `《${province}律师服务收费标准》${caseType}`
      text += `${caseStage ? caseStage + '，' : '，'}`
      if (this.result) {
        const { lawyerFeeStandardTextArr } = this.result
        lawyerFeeStandardTextArr.forEach((v) => {
          text += v.startText ? v.startText + ':' : ''
          text += v.endText + ';'
        })
      }
      return text
    },
  },
  methods: {
    IsIOS() {
      var userAgentInfo = navigator.userAgent
      var Agents = ['iPhone', 'iPad', 'iPod']
      var flag = false
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = true
          break
        }
      }
      return flag
    },
    addHistory() {
      console.log('==== addHistory ====')
      const type = 'lawyerFee'
      const { province, caseType, targetAmount, caseStage } = this
      const strTargetAmount = targetAmount
        .toString()
        .replace(/(^\s+)|(\s+$)/g, '')
        .replace(/\s/g, '')
      const conditionObj = {
        province,
        caseType,
        targetAmount: caseStage ? 0 : strTargetAmount,
        caseStage,
        result: this.result,
      }
      let title = ' ' + province + ' ' + caseType + ' '
      title += caseStage || strTargetAmount + '元'
      title += ' 律师费'
      console.log('addHistory 律师费 --- title', title)
      if (this.result && this.result.toString().trim() !== '0') {
        this.$axios
          .post(`${this.$base}/management/utils/history/add`, {
            type,
            condition: JSON.stringify(conditionObj),
            title,
          })
          .then((res) => {
            if (res.data.code === 200) {
              // this.$bus.$emit('add-history-suc', 'lawyerFee')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    async clickItem(item) {
      this.addHistory()
      const { condition, type } = item
      const conditionObj = JSON.parse(condition)
      console.log(item, conditionObj)
      if (type === 'lawyerFee') {
        const { province, caseType, targetAmount, caseStage } = conditionObj
        this.province = province || ''
        if (this.province) {
          await this.handleSelectProvice(this.province)
        }
        this.caseType = caseType || ''
        if (province && caseType) {
          if (caseStage) {
            this.caseStage = caseStage
            this.showCaseStage = true
            this.getResult({ province, caseType, caseStage })
          } else if (targetAmount) {
            this.targetAmount = targetAmount
            this.showCaseStage = false
            const StrTargetAmount = targetAmount
              .toString()
              .replace(/(^\s+)|(\s+$)/g, '')
              .replace(/\s/g, '')
            this.getResult({
              province,
              caseType,
              targetAmount: Number(StrTargetAmount),
            })
          }
        }
      }
    },
    // 设置字体大小
    setFontSize(size, index) {
      this.currentIndex = index || 0
      this.dealFontSize(size)
    },
    // 聚焦显示搜索框
    provinceFocus() {
      this.showProvincePanel = true
      this.BR = true
      this.getAddressList(this.province)
    },

    // 失焦 搜索框消失
    provinceBlur() {
      this.BR = false
      setTimeout(() => {
        this.showProvincePanel = false
      }, 100)
    },
    // 获取省份信息列表
    async getAddressList(val = '') {
      if (!this.BR) {
        return
      }
      let addprovince = ''
      const url = `${this.$base}/document/basicAdministrativeRegion/queryByParams?name=${val}&pageNum=0&level=1&pageSize=40`
      try {
        const res = await this.$axios.post(url)
        if (res.data && res.data.code === '200') {
          const { items } = res.data.data
          if (this.userInfo) {
            addprovince = this.userInfo.province || this.userInfo.originPlace
          }
          if (addprovince) {
            const index = items.findIndex((v) => v.shortName === addprovince)
            if (index !== -1) {
              items.splice(index, 1)
            }
            items.unshift({ shortName: addprovince })
          }
          this.provinceOption = items.map((item) => {
            return { text: item.shortName, value: item.shortName }
          })
          console.log(this.provinceOption, '获取省份信息列表 0000000000000000')
        }
      } catch (err) {
        console.log(err)
      }
    },
    showMoreProvince() {
      if (!this.showProvincePanel) {
        this.$refs.provinceMore.style.transform = 'rotate(180deg)'
      } else {
        this.$refs.provinceMore.style.transform = 'rotate(0deg)'
      }
      this.showProvincePanel = !this.showProvincePanel
    },
    // 清除省
    DeleteProvince() {
      this.province = ''
      this.handleSelectProvice(this.province)
    },
    // 选中切换省份
    handleSelectProvice(item) {
      console.log(item, '选中切换省份 11111111111111111111')
      // 点击了省份选择列表执行保存在本地：
      localStorage.setItem('province', JSON.stringify(item))
      // console.log('%c ' + item, 'color:' + 'green')
      this.province = item
      this.caseType = ''
      this.caseStage = ''
      this.targetAmount = '0'
      this.result = ''
      this.$axios
        .get(`${this.$base}/management/lawyer/service-fee/standard/getListStandardByProvince?province=${item}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.caseTypeOption = res.data.data.caseTypes
            this.caseStageOption = res.data.data.caseStages
            // if (!this.caseType) {
            //   this.caseType = this.caseTypeOption[0]
            // }
            // this.handleSelectCaseType(this.caseType)
          }
          console.log(this.caseTypeOption, this.caseStageOption)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    typeFocus() {
      this.typeBR = true
      this.showCaseTypePanel = true
    },
    typeBlur() {
      this.typeBR = false
      setTimeout(() => {
        this.showCaseTypePanel = false
      }, 100)
    },
    deleteCaseType() {
      console.log('----------')
      this.caseType = ''
      this.handleSelectCaseType(this.caseType)
    },
    // 选择切换案件类型
    handleSelectCaseType(item) {
      console.log(item, '选择切换案件类型 222222222222222222222222')
      this.caseType = item
      this.showCaseTypePanel = false
      if (!item) {
        this.showCaseStage = false
        this.targetAmount = '0'
        this.result = ''
        return
      }
      this.result = ''
      if (item !== '刑事案件') {
        this.showCaseStage = false
        this.caseStage = ''
        if (this.targetAmount) {
          this.handleBlurTargetAmount(this.targetAmount)
        }
      } else {
        this.showCaseStage = true
        // this.targetAmount = ''
        if (this.caseStage) {
          this.handleSelectCaseStage(this.caseStage)
        }
      }
    },
    // 选择切换案件阶段
    handleSelectCaseStage(item) {
      console.log(item, '选择切换案件阶段 33333333333333333333333333')
      this.result = ''
      this.showCaseStagePicker = false
      this.caseStage = item
      const { province, caseType, caseStage } = this
      if (!province) {
        this.$notify({
          type: 'danger',
          message: '请选择省份',
          duration: 800,
        })
        return
      }
      if (!caseType) {
        this.$notify({
          type: 'danger',
          message: '请选择案件类型',
          duration: 800,
        })
        return
      }
      if (!caseStage) {
        this.$notify({
          type: 'danger',
          message: '请选择案件阶段',
          duration: 800,
        })
        return
      }
      const params = { province, caseType, caseStage }
      console.log('选择切换案件阶段', params)
      this.getResult(params)
    },
    onInput(key) {
      this.$nextTick(() => {
        console.log(key, this.targetAmount, 'input')
        // 如果用户第一位输入的是小数点，则重置输入框内容
        if (this.targetAmount !== '' && this.targetAmount.substr(0, 1) === '.') {
          this.targetAmount = ''
        }
        // 清除“数字”和“.”以外的字符
        this.targetAmount = this.targetAmount.replace(/[^\d.]/g, '')
        // 只保留第一个. 清除多余的
        this.targetAmount = this.targetAmount.replace(/\.{2,}/g, '.')
        this.targetAmount = this.targetAmount.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
        // 只能输入两位小数
        this.targetAmount = this.targetAmount.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        if (this.targetAmount.indexOf('.') < 0 && this.targetAmount !== '') {
          // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          if (this.targetAmount.substr(0, 1) === '0' && this.targetAmount.length === 2) {
            this.targetAmount = this.targetAmount.substr(1, this.targetAmount.length)
          }
        }
      })
    },
    handleFocusAmount() {
      if (this.targetAmount === '0') {
        this.targetAmount = ''
      }
      this.showkeyboard = true
    },
    // 输入标的额失焦
    handleBlurTargetAmount() {
      // console.log('输入标的额失焦 33333333333333333333333333')
      this.result = ''
      this.showkeyboard = false
      const { province, caseType, targetAmount } = this
      if (!province) {
        this.$notify({
          type: 'danger',
          message: '请选择省份',
          duration: 800,
        })
        return
      }
      if (!caseType) {
        this.$notify({
          type: 'danger',
          message: '请选择案件类型',
          duration: 800,
        })
        return
      }
      if (!targetAmount || Number(targetAmount) < 0) {
        this.$notify({
          type: 'danger',
          message: '请输入标的额',
          duration: 800,
        })
        return
      }
      const params = { province, caseType, targetAmount: Number(targetAmount) }
      // console.log('输入标的额失焦', params)
      this.getResult(params)
    },
    // 得到计算结果和计算方式
    getResult(params) {
      this.$axios
        .post(`${this.$base}/management/lawyer/service-fee/standard/getLawyerServiceFee`, { ...params })
        .then((res) => {
          if (res.data.code === 200) {
            const lawyerFeeStandards = res.data.data.lawyerFeeStandards
            lawyerFeeStandards.push(res.data.data.lawyerFeeStandard)
            const targetAmount = Number(params.targetAmount)
            let endIndex = -1
            let lastEndMoney = 0 // 满足上一阶段的最大
            let lastMoney = 0 // 最后一阶段的剩余
            let rangeMoney = 0 // 金额间隔段
            const lawyerFeeStandardTextArr = lawyerFeeStandards.filter((v) => {
              if (targetAmount) {
                return Number(v.propertyAmountMin) < targetAmount
              } else {
                return v
              }
            })
            lawyerFeeStandardTextArr.forEach((item, index) => {
              const chargeRatioMin = Number(item.chargeRatioMin)
              const chargeRatioMax = Number(item.chargeRatioMax)
              const chargeRatioBase = Number(item.chargeRatioBase)
              const chargeAmountMin = Number(item.chargeAmountMin)
              const chargeAmountMax = Number(item.chargeAmountMax)
              const propertyAmountMax = Number(item.propertyAmountMax)
              const propertyAmountMin = Number(item.propertyAmountMin)
              if (propertyAmountMax > targetAmount) {
                endIndex = index
                if (endIndex > 0) {
                  lastEndMoney = lawyerFeeStandards[endIndex - 1].propertyAmountMax && Number(lawyerFeeStandards[endIndex - 1].propertyAmountMax)
                }
                lastMoney = targetAmount - lastEndMoney
              }
              rangeMoney = propertyAmountMax - propertyAmountMin + 1
              if (propertyAmountMin && propertyAmountMax) {
                if (propertyAmountMax === '-1') {
                  item.startText = `涉及财产金额 ${propertyAmountMin}元以上`
                } else {
                  item.startText = `涉及财产金额 ${propertyAmountMin} 至 ${propertyAmountMax} 元`
                }
              } else {
                item.startText = ''
              }
              if (chargeRatioBase) {
                item.endText = `收费标准比率固定${(chargeRatioBase * 100).toFixed(1)}% `
                if (index === endIndex) {
                  item.moneyRange = [parseInt(chargeRatioBase * lastMoney), parseInt(chargeRatioBase * lastMoney)]
                } else {
                  item.moneyRange = [parseInt(chargeRatioBase * rangeMoney), parseInt(chargeRatioBase * rangeMoney)]
                }
              } else if (chargeRatioMin && chargeRatioMax) {
                item.endText = `收费标准比率${(chargeRatioMin * 100).toFixed(1)}% 至 ${(chargeRatioMax * 100).toFixed(1)}%`
                if (index === endIndex) {
                  item.moneyRange = [parseInt(chargeRatioMin * lastMoney), parseInt(chargeRatioMax * lastMoney)]
                } else {
                  item.moneyRange = [parseInt(chargeRatioMin * rangeMoney), parseInt(chargeRatioMax * rangeMoney)]
                }
              } else if (chargeRatioMin && !chargeRatioMax) {
                item.endText = `收费标准比率固定${(chargeRatioMin * 100).toFixed(1)}% `
                if (index === endIndex) {
                  item.moneyRange = [parseInt(chargeRatioMin * lastMoney), parseInt(chargeRatioMin * lastMoney)]
                } else {
                  item.moneyRange = [parseInt(chargeRatioMin * rangeMoney), parseInt(chargeRatioMin * rangeMoney)]
                }
              } else if (!chargeRatioMin && chargeRatioMax) {
                item.endText = `收费标准比率固定${(chargeRatioMax * 100).toFixed(1)}% `
                if (index === endIndex) {
                  item.moneyRange = [parseInt(chargeRatioMax * lastMoney), parseInt(chargeRatioMax * lastMoney)]
                } else {
                  item.moneyRange = [parseInt(chargeRatioMax * rangeMoney), parseInt(chargeRatioMax * rangeMoney)]
                }
              } else if (chargeAmountMin || chargeAmountMax) {
                item.endText = `固定收费${chargeAmountMin}${chargeAmountMax ? '至' + chargeAmountMax : ''}元`
                if (index === endIndex) {
                  item.moneyRange = [chargeAmountMin || chargeAmountMax, chargeAmountMax || chargeAmountMin]
                } else {
                  item.moneyRange = [chargeAmountMin || chargeAmountMax, chargeAmountMax || chargeAmountMin]
                }
              }
              console.log(item, item.startText, item.endText, item.moneyRange)
            })
            let maxResult = 0
            let minResult = 0
            lawyerFeeStandardTextArr.forEach((v) => {
              minResult += v.moneyRange[0]
              maxResult += v.moneyRange[1]
            })
            this.result = {
              maxResult,
              minResult,
              lawyerFeeStandardTextArr,
            }
            if (!this.currentIndex) {
              this.currentIndex = 0
            }
            console.log(this.result, '得到计算结果和计算方式 ==========>')
            setTimeout(() => {
              let fontSize = 14 + this.currentIndex * 4
              if (this.$ua.i !== navigator.userAgent && this.IsIOS()) {
                // 适配
                fontSize = AdapterUtil.setSpText(fontSize)
                fontSize += 15
              }
              console.log('this.currentIndex', fontSize)
              this.dealFontSize(fontSize)
              this.showResult = true
            }, 200)
          } else {
            this.$notify({
              type: 'danger',
              message: res.data.message,
              duration: 800,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 处理导出和复制的文字
    handleText() {
      const { province, caseType, targetAmount, caseStage } = this
      let text = ''
      text += `${province}-${caseType}，`
      text += `${caseStage ? '案件阶段：' + caseStage + '  \n' : ''}`
      text += `${targetAmount ? '标的额：' + targetAmount + '元  \n' : ''}`
      if (this.result) {
        let jdMoney = ''
        const { minResult, maxResult } = this.result
        if (minResult === maxResult) {
          jdMoney = minResult
        } else {
          jdMoney = `${minResult} 至 ${maxResult}`
        }
        text += `律师费：${jdMoney}元  \n`
        text += '依据： \n'
        text += this.yjtext
      }
      return text
    },
    // 点击复制图标
    async copy() {
      this.addHistory()
      let text = '律师费  \n' + this.handleText()
      let url = window.location.href
      const res = await longUrl2ShortUrl(url)
      if (res.status === 200) {
        url = res.data
        text += '\n' + url
      }
      const transfer = document.createElement('input')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
    },
    // 点击分享
    async share() {
      this.addHistory()
      let text = window.location.origin + '/transform/tools/lawyerFee'
      if (this.result) {
        const { province, caseType, targetAmount, caseStage } = this
        const obj = { province, caseType, targetAmount, caseStage }
        text += `?data=${JSON.stringify(obj)}`
      }
      const res = await longUrl2ShortUrl(text)
      if (res.status === 200) {
        text = res.data
      }
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes('律师费计算', '律呗律师费计算', window.encodeURI(text))
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: '律师费计算',
            url: window.encodeURI(text),
          })
        )
      } else {
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          message: '已复制本网页地址，请粘贴到QQ或微信中',
        })
      }
    },
    // 点击反馈
    feedback() {
      this.$router.push({
        name: 'feedback',
      })
    },
    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    // 点击下载
    download() {
      // const time = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', time)
      this.addHistory()
      const text = '           律师费\n\n' + this.handleText()
      var blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
      const file = new File([blob], '律师费.txt', {
        type: 'text/plain;charset=utf-8',
      })
      const qiniu = new this.$qiniu()
      qiniu.UploadFile(
        'DEFAULT',
        file,
        {
          next: (file) => {},
          complete: () => {},
          error: () => {
            this.$toast('上传失败，上传终止')
          },
        },
        (fileopt, key, file) => {
          // 拼接完整的图片的地址
          const url = window.location.protocol + qiniuBase + '/' + key
          const name = url.substring(url.lastIndexOf('/') + 1)
          // const pathUrl = url.substring(0, url.lastIndexOf('/')) // 截取图片的路径
          const ua = navigator.userAgent
          if (ua === this.$ua.a) {
            // eslint-disable-next-line
            // AND2JS.showFilePreview(url)
            window.showFilePreview.postMessage(
              JSON.stringify({
                filePath: url,
                fileName: name,
              })
            )
          } else if (ua === 'fb-flutter-Webview') {
            // flutter环境
            // eslint-disable-next-line
            try {
              // 之前写的方法
              // window.fbDownloadFile.postMessage(url)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: url,
                  fileName: name,
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else if (ua === this.$ua.i) {
            this.iphone = true
            // ios环境
            // eslint-disable-next-line
            // window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
          } else {
            // window.location = url
            console.log('下载地址:', url)
          }
        }
      )
    },
    // 处理特殊字体
    dealFontSize(fontSize) {
      const bgSize = fontSize + 4
      const smSize = fontSize - 4
      const feeResultEL = document.getElementById('lawyerfeeResult').getElementsByTagName('span')[0]
      const smSpanELs = document.getElementsByClassName('smSPAN')
      // console.log(smSize, bgSize, fontSize, '==== 处理 ====')
      if (feeResultEL) {
        feeResultEL.style.setProperty('font-size', bgSize + 'px', 'important')
      }
      Array.prototype.slice.call(smSpanELs).forEach((span) => {
        span.style.setProperty('font-size', smSize + 'px', 'important')
        // console.log(span, span.style)
      })
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      if (this.$route.query.data) {
        const data = JSON.parse(this.$route.query.data) || {}
        const { province, caseType, targetAmount, caseStage } = data
        this.province = province || ''
        await this.handleSelectProvice(this.province)
        console.log(data, this.province)
        this.caseType = caseType || ''
        if (province && caseType) {
          if (caseStage) {
            this.caseStage = caseStage
            this.showCaseStage = true
            this.getResult({ province, caseType, caseStage })
          } else if (targetAmount) {
            this.targetAmount = targetAmount
            this.showCaseStage = false
            this.getResult({ province, caseType, targetAmount })
          }
        }
      }
      if (this.$refs.fontSizeComp) {
        this.$refs.fontSizeComp.fontSize = 20
        this.$refs.fontSizeComp.setFontSize()
      }
      let fontSize = 14
      if (this.$ua.i !== navigator.userAgent && this.IsIOS()) {
        // 适配
        fontSize = AdapterUtil.setSpText(fontSize)
        fontSize += 15
      }
      console.log('mounted--------------->>')
      this.setFontSize(fontSize)
    })
    // 获取本地保存的省份信息
    const provi = JSON.parse(localStorage.getItem('province')) || ''
    if (provi === '') {
      this.province = ''
    } else {
      this.province = provi
    }
  },
  async created() {
    console.log(this.userInfo, 'userInfo---created--')
    if (this.userInfo) {
      if (this.userInfo.province) {
        this.province = this.userInfo.province.replace('省', '')
      } else if (this.userInfo.originPlace) {
        this.province = this.userInfo.originPlace
      } else {
        this.province = ''
      }
    }
    await this.getAddressList()
    if (this.province) {
      await this.handleSelectProvice(this.province)
    }
  },
  watch: {
    province(val) {
      this.getAddressList(val.trim())
    },
  },
}
</script>
<style lang="stylus" scoped>
.box-top /deep/.van-dropdown-menu__bar
  box-shadow none
.box-top /deep/.van-dropdown-menu__item:nth-child(1)
  flex 1
  justify-content flex-start
.box-top /deep/.van-dropdown-menu__item:nth-child(2)
  flex 1
  justify-content flex-end
  margin-right 5px
.box-top /deep/ .van-dropdown-menu__title
  padding 0 10px 0 0
  min-width 10px
</style>
<style lang="stylus" scoped>
.tools-lawwyerFee
  position relative
  box-sizing border-box
  & .van-checkbox
    height 22px
  & .box-top
    margin-top 10px
    display flex
    justify-content space-between
    .left-box
      display flex
      justify-content flex-start
      man-width 40%
    .right-box
      display flex
      justify-content flex-end
      min-width 30%
      & .van-cell
        padding-right 0
        & .icon-xialaxuanze
          color #ccc
    .province-input-wrapper,.type-input-wrapper
      line-height 20px !important
      width 100%
      height 100%
      position relative
      display flex
      align-items center
      .judge-caseno
        font-size 0.373333rem
        color #969799
        font-weight lighter
      input
        height 90%
        font-size 14px
        flex-grow 1
        align-self center
        border none
        width 50%
      .jigou-label
        position absolute
        font-size 0.373333rem
        top 0px
        left 0px
        background #eee
        border-radius 3px
        z-index 9
        overflow auto
        line-height 40px
        height 40px
        margin 5px 0
        padding 0 10px
        width 70%
        display flex
        box-sizing border-box
        & .iconfont
          height 40px !important
          line-height 40px !important
      .liebiao
        width 100%
        position absolute
        top 30px
        left 0px
        background #ffffff
        box-shadow 0px 4px 8px 1px rgba(190, 190, 190, 0.64)
        border-radius 5px
        z-index 9999
        padding-bottom 30px
        overflow auto
        max-height 200px
        .load
          position absolute
          width 100%
        li
          width 100%
          height 40px
          line-height 40px
          font-size 14px
          color #666666
          text-indent 14px
          margin-top 5px
          border-bottom 1px solid #eee
          &.no-data
            color #333
            text-align center
  & .interest-content
    padding 0 15px 0
    & .box
      display flex
      align-items center
      justify-content space-between
      & .right /deep/ .van-cell
        padding-right 0
      & .left
        flex 1
      & .right
        flex 1
        text-align right
  & .interest-result
        margin-top 20px
        padding 0 15px 0
        display flex
        flex-direction row
        align-items center
        color red
        justify-content space-between
        & > span
          cursor pointer
          color orange
  & .btns-result
        margin-top 20px
        padding 0 15px 0
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        & > span
          cursor pointer
          color #0079FE
  & .result-yj
      margin-top 20px
      padding 0 15px 0
      color red
</style>
